import { BORouteIdEnum } from 'config/enums';
import { Navigate } from 'react-router-dom';
import RouteObject from 'types/interfaces/RouteObject';
import AuthGuard from 'shared/guards/AuthGuard';
import { lazy } from 'react';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Login from 'pages/AuthPages/Login';
import NotFoundPage from 'pages/NotFoundPage';
import { BORouterPaths } from 'config/constant';

const SchedulePage = lazy(() => import('pages/SchedulePage/SchedulePage'));
const TeamTablePage = lazy(() => import('features/Team/TeamTable'));
const AddTeamPage = lazy(() => import('features/Team/AddTeam/AddTeam'));
const ProductsTablePage = lazy(() => import('features/Products/ProductsTable/ProductsTable'));
const AddProductsPage = lazy(() => import('features/Products/AddProducts/AddProducts'));
const ClientClassTablePage = lazy(
  () => import('features/Clients/ClientClass/ClientClassTable/ClientClassTable'),
);
const AddClientClassPage = lazy(
  () => import('features/Clients/ClientClass/AddClientClass/AddClientClass'),
);
const CategoriesProductTablePage = lazy(
  () => import('pages/CategoriesProduct/CategoriesProductTable'),
);
const AddCategoriesProductPage = lazy(() => import('pages/CategoriesProduct/AddCategoriesProduct'));
const ClientCategoryTablePage = lazy(
  () => import('features/Clients/ClientCategory/ClientCategoryTable/ClientCategoryTable'),
);
const AddClientCategoryPage = lazy(
  () => import('features/Clients/ClientCategory/AddClientCategory/AddClientCategory'),
);
const AddWarehousePage = lazy(() => import('pages/WareHousePage/AddWareHousePage'));
const WarehouseTablePage = lazy(() => import('pages/WareHousePage/ListWareHousePage'));
const ZonePage = lazy(() => import('pages/ZonePage/ZonePage'));
const AddZonePage = lazy(() => import('pages/ZonePage/AddZonePage'));
const ClientsTablePage = lazy(() => import('pages/Clients/ListClientsPage'));
const AddSectorPage = lazy(() => import('pages/SectorsPage/AddSectorPage'));
const AddClientPage = lazy(() => import('pages/Clients/AddClientPage'));
const AddCodePromoPage = lazy(
  () => import('pages/CodePromoPages/AddCodePromoPage/AddCodePromoPage'),
);
const AddDealPage = lazy(() => import('pages/DealsPage/AddDeals/AddDealsPage'));
const AddProfileAccessPage = lazy(() => import('pages/ProfileAccess/AddProfileAccessPage'));
const ProfileAccessTablePage = lazy(() => import('pages/ProfileAccess/ProfileAccessTablePage'));
const AdsPage = lazy(() => import('pages/AdsPage/AdsPage'));
const AddAdPage = lazy(() => import('pages/AdsPage/AddAdPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage/NotificationsPage'));
const AddNotificationPage = lazy(() => import('pages/NotificationsPage/AddNotificationPage'));
const AddOrderPage = lazy(() => import('pages/OrdersPage/AddOrder/AddOrderPage'));
const ListOrderPage = lazy(() => import('pages/OrdersPage/ListOrders/ListOrderPage'));
const DealsListPage = lazy(() => import('pages/DealsPage/DealsList/DealsListPage'));
const CodePromoListPage = lazy(
  () => import('pages/CodePromoPages/CodePromoListPage/CodePromoListPage'),
);
const PageZoneListManageLP = lazy(
  () => import('pages/PageManageLP/PageZoneListManageLP/PageZoneListManageLP'),
);
const PageEditManageLP = lazy(() => import('pages/PageManageLP/PageEditManageLP/PageEditManageLP'));
const CategoriesLogisticsListPage = lazy(
  () => import('pages/CategoriesLogisticsPage/CategoriesLogisticsListPage'),
);
const AddCategoriesLogisticsPage = lazy(
  () => import('pages/CategoriesLogisticsPage/AddCategoriesLogisticsPage'),
);
const RoutesConfig: RouteObject[] = [
  {
    path: BORouteIdEnum.Root,
    element: <Navigate to={BORouteIdEnum.BO} />,
  },
  {
    path: BORouteIdEnum.Auth,

    element: (
      <AuthGuard guest={true}>
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: BORouteIdEnum.Root,
        element: <Navigate to={BORouteIdEnum.SignIn} />,
      },
      { path: BORouteIdEnum.SignIn, element: <Login /> },
    ],
  },

  {
    path: BORouteIdEnum.BO,
    element: (
      <AuthGuard guest={false}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: BORouteIdEnum.Root,
        element: <Navigate to={BORouteIdEnum.Teams} />,
      },
      {
        id: 0,
        path: BORouteIdEnum.Teams,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <TeamTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddTeamPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddTeamPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Products,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ProductsTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddProductsPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddProductsPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.ClientsClass,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ClientClassTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddClientClassPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddClientClassPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Categories,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <CategoriesProductTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddCategoriesProductPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddCategoriesProductPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.ClientsCategory,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ClientCategoryTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddClientCategoryPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddClientCategoryPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Zone,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ZonePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddZonePage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddZonePage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.WarehousePath,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <WarehouseTablePage />,
          },

          {
            path: BORouteIdEnum.Add,
            element: <AddWarehousePage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddWarehousePage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Sector,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ZonePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddSectorPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddSectorPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Clients,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ClientsTablePage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddClientPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddClientPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.ProfileAccess,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ProfileAccessTablePage />,
          },

          {
            path: BORouteIdEnum.Add,
            element: <AddProfileAccessPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddProfileAccessPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Ad,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <AdsPage />,
          },

          {
            path: BORouteIdEnum.Add,
            element: <AddAdPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddAdPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Notification,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <NotificationsPage />,
          },

          {
            path: BORouteIdEnum.Add,
            element: <AddNotificationPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddNotificationPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Schedule,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <SchedulePage />,
          },
        ],
      },
      {
        path: BORouteIdEnum.Promo,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <CodePromoListPage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddCodePromoPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddCodePromoPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Deals,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <DealsListPage />,
          },
          {
            path: BORouteIdEnum.Add,
            children: [
              {
                path: BORouteIdEnum.Root,
                element: <AddDealPage />,
              },
              {
                path: BORouteIdEnum.Discount,
                children: [
                  {
                    path: BORouteIdEnum.Root,
                    element: <Navigate to={BORouterPaths.AddDealsPath} replace />,
                  },
                  {
                    path: BORouteIdEnum.id,
                    element: <AddDealPage />,
                  },
                ],
              },

              {
                path: BORouteIdEnum.id,
                element: <AddDealPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.Order,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <ListOrderPage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddOrderPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddOrderPage />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.ManageLP,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <PageZoneListManageLP />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <PageEditManageLP />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <PageEditManageLP />,
              },
            ],
          },
        ],
      },
      {
        path: BORouteIdEnum.CategoriesLogistic,
        children: [
          {
            path: BORouteIdEnum.Root,
            element: <CategoriesLogisticsListPage />,
          },
          {
            path: BORouteIdEnum.Add,
            element: <AddCategoriesLogisticsPage />,
            children: [
              {
                path: BORouteIdEnum.id,
                element: <AddCategoriesLogisticsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: BORouteIdEnum.Any,
    element: <NotFoundPage />,
  },
];
export default RoutesConfig;
